<template>
  <div class="saleMan">
    <div class="leftMan">当前绑定业务员:</div>
    <div class="minMan">
      <div class="rightMan" v-if="saleManList.length > 0">
        <div v-for="(v, i) in saleManList" :key="i">
          <div class="user_name">{{ v.user_name }}</div>
          <div class="name"> {{ v.name || "/" }} </div>
        </div>
      </div>
      <div class="rightMan" v-else>
        <div>/</div>
      </div>
    </div>
    <!-- <div class="operate-btn">
      <div class="cancel" @click="toleave">关闭</div>
    </div> -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userCenterList = createNamespacedHelpers("userCenterList");
export default {
  data() {
    return {};
  },
  computed: {
    ...userCenterList.mapState(["saleManList"]),
  },
  methods: {
    // ...userCenterList.
    toleave() {
      this.$router.push({ path: "/usersCenterList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.saleMan {
  padding: 20px;
  .minMan {
    display: flex;
  }
  .rightMan {
    overflow-y: scroll;
    max-height: 400px;
    width: 100%;
    color: #333;
  }
  .rightMan::-webkit-scrollbar-thumb {
    // background: #000;
  }
  .user_name {
    font-weight: bold;
    font-size: 14px;
    margin: 20px 0 10px;
  }
  .name {
    font-size: 12px;
    padding: 5px;
    background-color: #f6f6fa;
    display: inline-block;
  }
}
.leftMan {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-content: center;
  line-height: 20px;
  margin-bottom: 20px;
}
.leftMan::before {
  width: 4px;
  height: 20px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
}
.operate-btn {
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  .save {
    text-align: center;
    line-height: 40px;
    margin-right: 10px;
    width: 200px;
    height: 100%;
    background: #00bfbf;
    border-radius: 4px;
    color: #fff;
  }
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 200px;
    height: 100%;
    border-radius: 4px;
    background: #aaaaaa;
  }
}
</style>
