<template> 
    <div class="multipleAccounts">
        <el-table :data="searchListData" border style="width: 100%">
            <el-table-column label="账号" align="center">
                <template slot-scope="{row}">
                    <span style="color:#06B7AE;cursor: pointer;" @click="handleUserName(row)">{{row.userName}}</span><span v-if="row.primary == 1" style="color:red;margin-left:4px">(主账号)</span>
                </template>
            </el-table-column>
            <el-table-column prop="mobile" label="绑定手机号" align="center"></el-table-column>
            <el-table-column label="绑定店铺数" align="center">
                <template slot-scope="{row}">
                    <span style="color:#06B7AE;cursor: pointer;" @click="openAccountShops(row.id)">{{row.shopNum}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <span class="btsed" @click="handleResetId(scope.row)">重置密码</span>
                    <span class="btsed" @click="handRestPassword(scope.row)">更换手机号</span>
                </template>
            </el-table-column>
        </el-table>
        <div style="margin-top: 50px;">
            <div style="margin: 20px 0px;color: #00BFBF;">手机号更换记录</div>
            <div class="merch-table scorr-roll"  :style="`max-height: ` + 440 + `px`">
                <el-table :data="mobileList" v-loading="mobileLoading" border style="width: 100%">
                    <el-table-column label="类型" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.type*1 == 1 ? '创建' : '修改' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="编辑时间" align="center"></el-table-column>
                    <el-table-column prop="userName" label="账号" align="center"></el-table-column>
                    <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="realName" label="操作者" align="center"></el-table-column>
                </el-table>
            </div>
            <!-- 分页 -->
            <div class="merch-page">
                <el-pagination
                    :current-page="queryParams.page"
                    background
                    @current-change="handleCurrentChange"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
        <el-dialog title="重置密码" :visible.sync="resetDialog" width="30%">
            <div style="margin-bottom:15px">确认重置密码？密码重置后，初始密码为【a123456】</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="resetDialog = false">取 消</el-button>
                <el-button type="primary" @click="handleReset" :loading="buttonLoading">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="更换手机号" :visible.sync="changeMobile" width="30%">
            <el-form ref="causeForm" :model="causeForm" :rules="rules" label-width="120px">
                <el-form-item label="单位名称：">
                    <span>{{causeForm.enterprise}}</span>
                </el-form-item>
                <el-form-item label="绑定手机号：">
                    <span>{{causeForm.mobile}}</span>
                </el-form-item>
                <el-form-item label="更换手机号" prop="newMobile">
                    <el-input v-model="causeForm.newMobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
            </el-form>
            <div style="color:red;margin-left:40px;">更换成功后，将不再绑定当前会员，请谨慎操作</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="changeMobile = false">取 消</el-button>
                <el-button type="primary" @click="handleSave" :loading="btnLoading">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="绑定店铺" :visible.sync="accountDialog" width="60%">
            <el-table :data="accountShopsData" border style="width: 100%">
                <el-table-column prop="userName" label="账号" align="center"></el-table-column>
                <el-table-column prop="enterprise" label="单位名称" align="center"></el-table-column>
                <el-table-column prop="mobile" label="绑定手机号" align="center"></el-table-column>
                <el-table-column prop="createTime" label="绑定时间" align="center"></el-table-column>
            </el-table>
        </el-dialog>

        <el-dialog title="修改账号" :visible.sync="isChangeName" width="28%">
            <el-form ref="accounUserName" :model="accounUserName" :rules="accountRules" label-width="120px">
                <el-form-item label="修改账号" prop="account">
                    <el-input v-model.trim="accounUserName.account" placeholder="请输入账号" clearable></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="isChangeName = false">取 消</el-button>
                <el-button type="primary" @click="handleChangeAccount">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const userCenterList = createNamespacedHelpers("userCenterList");
export default {
    data() {
        return {
            searchListData:[],
            resetDialog:false,
            accountDialog:false,
            changeMobile:false,
            buttonLoading:false,
            btnLoading:false,
            mobileLoading:false,
            isChangeName:false,
            passWord:'',
            accounUserName:{
                account:""
            },
            causeForm:{
                newMobile:''
            },
            queryParams:{
                id:this.$route.query.id,
                page:1,
                perPage:10,
            },
            total:0,
            accountShopsData:[],
            mobileList:[],
            rules:{
                newMobile: [
                    { required: true, message: '更换手机号必填', trigger: 'change' },
                ],
            },
            accountRules:{
                account: [
                    { required: true, message: '账号必填', trigger: 'change' },
                ],
            }
        }
    },
    computed: {
    ...commonIndex.mapState(["mainHright"])
  },
    mounted(){
        this.getMultipleList()
        this.getList()
    },
    methods:{
        ...userCenterList.mapActions([
            "getMultipleAccounts",
            "resetAccountPassword",
            "changeAccountMobile",
            "getAccountShops",
            "getMobileChangeLog",
            "modifyAccount"
        ]),
        handleUserName(row){
            this.accounUserName.account = row.userName
            this.accounUserName.id = row.id
            this.isChangeName = true
        },
        async handleChangeAccount(){
            let res = await this.modifyAccount(this.accounUserName)
            if(res.code*1 == 200){
                this.$message({
                        type: "success",
                        message: '修改成功',
                    });
            }
            this.isChangeName = false
            this.getMultipleList()
        },
        handleCurrentChange(e){
            this.queryParams.page = e
            this.getList()
        },
        async getList(){
            this.mobileLoading = true
            let {data} = await this.getMobileChangeLog(this.queryParams)
            this.mobileLoading = false
            this.total = data.total
            this.mobileList = data.data
        },
        async openAccountShops(id){
            this.accountDialog = true
            let data = await this.getAccountShops({id:id})
            this.accountShopsData = data.data
        },
        async handleSave(){
            this.$refs.causeForm.validate(async (valid) =>{
                if(valid){
                    let parmas = {
                        id: this.causeForm.id,
                        newMobile: this.causeForm.newMobile
                    }
                    this.btnLoading = true
                    let res = await this.changeAccountMobile(parmas)
                    this.btnLoading = false
                    if(res.code == 200){
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                    }else{
                        this.$message({
                            type: "error",
                            message: res.message,
                        });
                    }
                    this.changeMobile = false
                    this.getList()
                    this.getMultipleList()
                }
            })
        },
        handleResetId(row){
            this.passWord = row.id 
            this.resetDialog = true
        },
        async handleReset(){
            this.buttonLoading = true
            let res = await this.resetAccountPassword({ id:this.passWord })
            this.buttonLoading = false
            if(res.code == 200){
                this.$message({
                    type: "success",
                    message: res.message,
                });
            }
            this.resetDialog = false
        },
        async getMultipleList(){
            let { data } = await this.getMultipleAccounts({ id:this.$route.query.id })
            this.searchListData = data
        },
        handRestPassword(val){
            this.causeForm = val
            this.changeMobile = true
            if (this.$refs.causeForm !== undefined) this.$refs.causeForm.resetFields()
        },
    }
}
</script>

<style lang="scss" scoped>
    .multipleAccounts{
        padding-top: 30px;
        .mobile-stl{
            margin: 20px 13px;
            font-size: 14px;
            color: #606266;
        }
        .merch-table {
            margin-top: 1%;
            width: 100%;
            height: 77%;
        }
        .merch-page {
            margin-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            display: flex;
            align-content: center;
            justify-content: right;
        }
    }
</style>