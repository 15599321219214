<template>
  <div>
    <div class="message scorr-roll">
      <div class="Form-BOX">
        <el-form label-width="200px">
          <el-form-item label="单位简称：">
            <div class="from-input">
              <el-input v-model="userInfo.shopName"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="单位名称：">
            <div class="from-input">
              <el-input v-model="userInfo.enterprise"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="证件类型：">
            <div class="from-input">
              <el-select
                v-model="userInfo.unitType"
                placeholder="请选择"
                style="width: 300px"
              >
                <el-option
                  v-for="item in userUnitType"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="证件号码：">
            <div class="from-input">
              <el-input v-model="userInfo.serialNumber"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="会员类型：">
            <div class="from-input">
              <el-select
                v-model="userInfo.type"
                placeholder="请选择"
                style="width: 300px"
              >
                <el-option
                  v-for="item in userType"
                  :key="item.id"
                  :label="item.type_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="法人姓名：">
            <div class="from-input">
              <el-input v-model="userInfo.legalEntity"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="企业注册地：">
            <el-cascader
              v-model="userInfo.areaList"
              :options="AreaList"
              :props="propsValue"
              clearable
              placeholder="省-市-区"
              style="width: 300px"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：">
            <div class="from-input">
              <el-input v-model="userInfo.address"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="手机号：">
            <div class="from-input">
              <el-input v-model="userInfo.mobile"></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item label="登录手机号：">
            <div class="from-input">
              <el-input v-model="userInfo.account" disabled>
                <span slot="suffix" class="green" @click="Editphone">编辑</span>
              </el-input>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="单位内码：">
            <div class="from-input fx">
              <el-input
                v-model="userInfo.erpCode"
                placeholder="请输入（ERP往来单位内码）"
              ></el-input>
              <div class="green" style="width: 80px; text-align: right">
                <span @click="Manualpush(userInfo.wldwid)">手动推送</span>
                <span
                  style="margin-left: 10px"
                  @click="ERPalpush(userInfo.wldwid)"
                  >手动同步</span
                >
              </div>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="联系人内码：">
            <div class="from-input">
              <el-input
                v-model="userInfo.contactsCode"
                placeholder="请输入"
              ></el-input>
            </div>
          </el-form-item> -->
          <!-- <el-form-item label="多店：" v-if="userInfo.more">
            <div class="from-input" v-if="userInfo.more.length > 0">
              <el-tag
                v-for="(v, i) in userInfo.more"
                :key="i"
                style="margin-right: 10px"
                >{{ v.enterprise }}</el-tag
              >
            </div>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
    <div class="compile-BTN">
      <div class="cancel-btn" @click="toleave">关闭</div>
      <div class="cancel-btn save-btn" @click="conserve">保存</div>
    </div>
    <el-dialog
      title="编辑登录手机号"
      :visible.sync="dialogVisible"
      width="400px"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :model="form" label-width="120px" :rules="rulesform">
        <el-form-item label="登录手机号：" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="解除多店关系：">
          <el-switch
            v-model="form.unbinding"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <el-button type="primary" @click="upedit">提交</el-button>
        <el-button class="huibtn" @click="dialogVisible = false"
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const userCenterList = createNamespacedHelpers("userCenterList");
const Audit = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      dialogVisible: false, //编辑手机号弹窗
      labelPosition: true,
      form: {},
      options: [],
      value: "",
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: false,
      }, //级联选择器配置项
      userData: {
        base: {},
      },
      areaArr: [],
      form: {
        userId: this.$route.query.id,
        mobile: "",
        unbinding: false,
      },
      rulesform: {
        mobile: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入11位有效手机号码。",
          },
        ],
      },
    };
  },
  computed: {
    ...userCenterList.mapState(["userInfo"]),
    ...commonIndex.mapState(["userType", "AreaList", "userUnitType"]),
  },
  methods: {
    ...userCenterList.mapActions([
      "postUserEdit",
      "postupdateAccount",
      "postbusinessscope",
      "geterpCodeMatch",
    ]),
    // 去保存
    async conserve() {
      if (this.userInfo.areaList.length == 0) {
        this.userInfo.province = this.userInfo.areaList[0] || "";
        this.userInfo.city = this.userInfo.areaList[1] || "";
        this.userInfo.district = this.userInfo.areaList[2] || "";
        this.$message({
          message: "省市区必选",
          type: "warning",
        });
        return;
      }
      this.userInfo.province = this.userInfo.areaList[0];
      this.userInfo.city = this.userInfo.areaList[1];
      this.userInfo.district = this.userInfo.areaList[2];
      this.userInfo.id = Number(this.$route.query.id);
      this.userInfo.wldwid = this.userInfo.erpCode;
      await this.postUserEdit(this.userInfo);
    },
    toleave() {
      this.$router.push({ path: "/usersCenterList" });
    },
    //编辑手机号弹窗
    Editphone() {
      this.form.mobile = this.userInfo.account;
      this.form.unbinding = 0;
      this.dialogVisible = true;
    },
    //编辑手机号弹窗
    upedit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          var form = { ...this.form };
          this.postupdateAccount(form).then((res) => {
            if (res.code == "000000") {
              this.$message.success("修改成功");
              this.dialogVisible = false;
              this.$parent.getUser();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    //ERP经营范围手动推送
    Manualpush(wldwid) {
      this.geterpCodeMatch({
        erpCode: wldwid,
      }).then((res) => {
        if (res.code == "000000") {
          // this.$message.success("手动同步成功");
          this.postbusinessscope({
            wldwid: wldwid,
          }).then((res) => {
            if (res.code == "000000") {
              this.$message.success("手动推送成功");
            }
          });
        }
      });
    },
    //ERP手动同步
    ERPalpush(wldwid) {},
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-top: 30px;
  width: 100%;
  max-height: 60vh;
  // height: 75vh;
  .Form-BOX {
    // width: 650px;
    display: flex;
    justify-content: center;
    max-height: 70vh;
    .from-input {
      max-width: 300px;
    }
  }
}
.compile-BTN {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  line-height: 40px;
  font-size: 14px;

  .cancel-btn {
    width: 100px;
    height: 40px;
    background: #f5f6f7;
    color: #c6c7c9;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .save-btn {
    margin-left: 20px;
    background: #06b7ae;
    color: #fff;
  }
}
::v-deep .el-input__inner {
  border-radius: 4px !important;
}
.green {
  color: #06b7ae;
  font-size: 14px;
  cursor: pointer;
  margin-right: 3px;
}
.huibtn {
  background: #f5f6f7;
  color: #383b46;
  border: 1px #f5f6f7 solid;
}
</style>
