<template>
  <div class=" ">
    <div class="pay-box">
      <!-- <div class="pay">
        药易购白条支付<el-switch
          v-model="forCorporate.yygSwitch"
          active-color="#06b7ae"
          inactive-color="#d7d7d7"
          :disabled="$route.query.type == 1"
        >
        </el-switch>
      </div> -->
      <!-- <div class="pay">
        对公转账支付<el-switch
          v-model="forCorporate.switch"
          active-color="#06b7ae"
          inactive-color="#d7d7d7"
          :disabled="$route.query.type == 1"
        >
        </el-switch>
      </div> -->
      <div class="pay">
        在线支付<el-switch
          v-model="zx"
          active-color="#06b7ae"
          inactive-color="#d7d7d7"
          disabled
        >
        </el-switch>
      </div>
      <!-- <div class="pay">
        药聚力白条支付<el-switch
          v-model="pinan"
          active-color="#06b7ae"
          inactive-color="#d7d7d7"
          disabled
        >
        </el-switch>
      </div> -->
    </div>
    <div class="compile-BTN">
      <div class="cancel-btn" @click="toleave">关闭</div>
      <div class="cancel-btn save-btn" @click="changsliitem">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userCenterList = createNamespacedHelpers("userCenterList");
const BasicsIndex = createNamespacedHelpers("Basics");
export default {
  data() {
    return {
      dui: false,
      zx: true,
      pinan: true,
      forCorporate: {
        switch: false,
        yygSwitch: false,
      },
      paylist: [],
    };
  },

  created() {
    this.GetbusinessGetPayTypeApi();
  },
  methods: {
    ...userCenterList.mapActions([
      "getusergetPayType",
      "postuserreplacePayType",
    ]),
    toleave() {
      this.$router.push({ path: "/usersCenterList" });
    },
    async GetbusinessGetPayTypeApi() {
      let res = await this.getusergetPayType({
        userId: this.$route.query.id,
      });
      console.log(res);
      if (res.code == "000000") {
        // this.forCorporate.switch=res.content.some((item)=>{
        //   return  item.payTypeName == "对公转账"&&item.deactivate == 0
        // })
        this.forCorporate.switch = res.content.some((item) => {
          return item.payTypeName == "对公转账" && item.deactivate == 0;
        });
        this.forCorporate.yygSwitch = res.content.some((item) => {
          return item.payType == "40" && item.deactivate == 0;
        });
        this.paylist = res.content;
        // res.content.forEach((item) => {
        //   if (item.payTypeName == "对公转账") {
        //     if (item.deactivate == 0) {
        //       item.switch = true;
        //     } else {
        //       item.switch = false;
        //     }
        //     this.forCorporate = item;
        //   }
        // });
      }
    },
    async changsliitem() {
      let params = [];
      this.paylist.forEach((item) => {
        // if(item.payTypeName=='对公转账'){
        //   if(this.forCorporate.switch){
        //     item.deactivate=0
        //   }else{
        //     item.deactivate=1
        //   }
        //   params.push(item)
        // }
        if (item.payType == "40") {
          if (this.forCorporate.yygSwitch) {
            item.deactivate = 0;
          } else {
            item.deactivate = 1;
          }
          params.push(item);
        }
      });
      // console.log(params)
      // let params={}
      // this.paylist.forEach(item=>{
      //   if(item.payTypeName=='对公转账'){
      //     if(this.forCorporate.switch){
      //       item.deactivate=0
      //     }else{
      //       item.deactivate=1
      //     }
      //     params=item
      //   }
      // })
      // if (this.forCorporate.switch) {
      //   this.forCorporate.deactivate = 0;
      // } else {
      //   this.forCorporate.deactivate = 1;
      // }
      // if (this.forCorporate.yygSwitch) {
      //   this.forCorporate.yygSwitchapi = 0;
      // } else {
      //   this.forCorporate.yygSwitchapi = 1;
      // }
      // console.log(this.forCorporate);
      let res = await this.postuserreplacePayType(params);
      if (res.code == "000000") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.compile-BTN {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  line-height: 40px;
  font-size: 14px;
  .cancel-btn {
    cursor: pointer;
    width: 100px;
    height: 40px;
    background: #f5f6f7;
    color: #c6c7c9;
    text-align: center;
    border-radius: 5px;
  }
  .save-btn {
    margin-left: 20px;
    background: #06b7ae;
    color: #fff;
  }
}
.pay-box {
  padding: 0 100px;
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  .pay {
    display: flex;
    align-items: center;
    > div {
      margin-left: 10px;
    }
  }
}
</style>
