<template>
  <div class="business">
    <div class="business-1-box">
      <div
        class="business-1"
        v-for="(item, index) in userScopeBusiness"
        :key="index"
      >
        <div class="title exceed">{{ item.name }}</div>
        <div class="swith">
          <el-switch
            v-model="item.status"
            active-color="#06b7b0"
            inactive-color="#c9cdd4"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </div>
        <div></div>
        <div class="input-box">
          <el-date-picker
            editable
            v-model="item.expiration_time"
            type="date"
            format="yyyy-MM-dd"
            placeholder="选择日期时间"
            :picker-options="pickerOptions"
            :class="item.hint ? 'hint' : ''"
            :disabled="item.expiration_time == '9999-12-31' ? true : false"
          >
          </el-date-picker>
        </div>
        <div class="checks">
          <el-radio-group v-model="item.expiration_time">
            <el-checkbox
              :disabled="!item.status"
              v-model="item.expiration_time == `9999-12-31`"
              @change="
                item.expiration_time == '9999-12-31'
                  ? (item.expiration_time = '')
                  : (item.expiration_time = '9999-12-31')
              "
            >
              长期
            </el-checkbox>
          </el-radio-group>
        </div>
        <!-- <div class="hinttext" v-if="item.hint">
         未选择效期
        </div> -->
      </div>
    </div>

    <div class="compile-BTN">
      <div class="cancel-btn" @click="toleave">关闭</div>
      <div class="cancel-btn save-btn" @click="saveUser">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userCenterList = createNamespacedHelpers("userCenterList");
const Audit = createNamespacedHelpers("Audit");
export default {
  data() {
    return {
      checked: true,
      value: "",
      input: "",
      pickerOptions: {
        //禁用当前日期之前的日期
        // 需求25328
        // disabledDate(time) {
        //   return time.getTime() < Date.now() - 8.64e7;
        // },
      },
      userScopeBusiness: {},
    };
  },
  computed: {
    // ...userCenterList.mapState(["userScopeBusiness"]),
  },
  created() {
    this.getcopeBusiness();
  },
  methods: {
    ...userCenterList.mapActions(["getScopeBusiness"]),
    ...Audit.mapActions([
      "postmemberAuditsaveScopeBusiness",
      "postv2memberAuditsaveScopeBusiness",
    ]),
    // 获取
    async getcopeBusiness() {
      let data = await this.getScopeBusiness({ user_id: this.$route.query.id });
      data.forEach((item) => {
        this.$set(item, "hint", false);
        let time = null;
        if (item.expiration_time == null) {
          time = [];
        } else {
          time = item.expiration_time.split(" ");
        }

        item.expiration_time = time[0];
      });
      this.userScopeBusiness = data;
    },
    async saveUser() {
      let NewArr = {
        user_id: this.$route.query.id,
        business_effective: [],
      };
      // this.manageData
      this.userScopeBusiness.forEach((item) => {
        if (item.expiration_time && item.status == 1) {
          NewArr.business_effective.push(item);
        }
        if (!item.expiration_time && item.status == 1) {
          item.hint = true;
        }
      });
      let num = this.userScopeBusiness.filter((item) => {
        return !item.expiration_time && item.status == 1;
      });
      if (num.length > 0) {
        return false;
      }
      if (NewArr.business_effective.length == 0) {
        this.$message({
          message: "未选择经营资质",
          type: "error",
        });
        return;
      }
      NewArr.business_effective.forEach((v, i) => {
        v.expiration_time = this.dateFormat(v.expiration_time);
      });
      NewArr.business_effective.forEach((item) => {
        delete item.hint;
      });
      let data = await this.postv2memberAuditsaveScopeBusiness(NewArr);
      if (data.code == 200) {
        this.getScopeBusiness({ user_id: this.$route.query.id });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
        });
      }
    },

    toleave() {
      this.$router.push({ path: "/usersCenterList" });
    },
    // 时间格式转化
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
  },
};
</script>
<style lang='scss' scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
.el-checkbox__label {
  color: black;
}
</style>
<style lang="scss" scoped>
.business {
  width: 100%;
  .business-1-box {
    width: 100%;
    max-height: 60vh;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: left;
    overflow-y: scroll;
    .business-1 {
      margin-top: 10px;
      width: 50%;
      min-width: 500px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        min-width: 70px;
        width: 25%;
        color: #333 !important;
        height: 100%;
        font-size: 14px;
      }
      .input-box {
        margin-left: 10px;
        min-width: 150px;
        height: 40px;
        border-radius: 4px;
      }
    }
    .checks {
      margin-left: 10px;
    }
  }
  .operate-btn {
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    margin-top: 80px;
    justify-content: center;
    font-size: 14px;
    .save {
      text-align: center;
      line-height: 32px;
      margin-left: 60px;
      width: 88px;
      height: 100%;
      background: #00bfbf;
      border-radius: 4px;
      color: #fff;
    }
    .cancel {
      text-align: center;
      line-height: 32px;
      width: 88px;
      height: 100%;
      border-radius: 4px;
      background: #aaaaaa;
    }
  }
}
.compile-BTN {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  line-height: 40px;
  font-size: 14px;
  .cancel-btn {
    cursor: pointer;
    width: 100px;
    height: 40px;
    background: #f5f6f7;
    color: #c6c7c9;
    text-align: center;
    border-radius: 5px;
  }
  .save-btn {
    margin-left: 20px;
    background: #06b7ae;
    color: #fff;
  }
}
.hint {
  border: 1px solid #f00;
  height: 42px;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px;
}
.hinttext {
  font-size: 12px;
  margin-left: 5px;
  color: #f00;
}
::v-deep .el-input__inner {
  position: relative;
  top: -5px;
}
::v-deep .el-icon-circle-close {
  position: relative;
  top: -5px;
}
::v-deep .el-icon-date {
  position: relative;
  top: -5px;
}
</style>
