<template>
  <div class="MERCH usersRedact">
    <div class="merch-card">
      <div class="absde">
        <span @click="openlog()" style="cursor: pointer;">
          <span>日志记录</span>
          <span class="el-icon-arrow-right"></span>
        </span>
      </div>
      <!-- tag标签 -->
      <div class="merch-tag">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="0"> </el-tab-pane>
          <el-tab-pane label="会员资质" name="1"></el-tab-pane>
          <el-tab-pane label="收货信息" name="2"> </el-tab-pane>
          <el-tab-pane label="经营范围" name="3"></el-tab-pane>
          <!-- <el-tab-pane label="业务员关系" name="4"> </el-tab-pane> -->
          <el-tab-pane label="多店/账号" name="5"> </el-tab-pane>
          <el-tab-pane label="支付方式" name="6"> </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 基本信息 -->
      <div v-if="activeName == 0">
        <Message />
      </div>
      <!-- 会员资质 -->
      <div v-if="activeName == 1">
        <Certification />
      </div>
      <!-- 收货信息 -->
      <div v-if="activeName == 2">
        <Harvest />
      </div>
      <!-- 经营范围 -->
      <div v-if="activeName == 3">
        <Business />
      </div>
      <!-- 业务员关系 -->
      <!-- <div v-if="activeName == 4">
        <saleMan />
      </div> -->
      <!-- 多店/账号 -->
      <div v-if="activeName == 5">
        <multipleAccounts />
      </div>
      <!-- 支付方式 -->
      <div v-if="activeName == 6">
        <payWay />
      </div>
    </div>
    <!-- 日志记录 -->
    <el-dialog
      title="日志记录"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="1100px"
      v-if="dialogVisible"
    >
      <el-form ref="queryForm" :model="queryParams" :inline="true">
        <el-form-item prop="keywords">
          <el-input
            v-model="queryParams.keywords"
            placeholder="关键词"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleQuery">
            搜 索
          </el-button>
          <span style="color: red; margin-left: 20px"
            >更新时间倒叙排列，支持针对描述模糊搜索</span
          >
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 10px">近显示最近一个月更新数据</div>
      <el-table
        v-loading="loading"
        border
        :data="logList"
        :header-cell-style="{ background: '#F2F3F5' }"
      >
        <el-table-column label="更新时间" align="center" prop="createTime">
          <template slot-scope="scope">
            <div>{{ scope.row.createTime || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="类型" align="center" prop="typeStr">
          <template slot-scope="scope">
            <div>{{ scope.row.typeStr || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作人" align="center" prop="operatorName">
          <template slot-scope="scope">
            <div>{{ scope.row.operatorName || "-" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="描述" align="center" prop="description">
          <template slot-scope="scope">
            <div>{{ scope.row.description || "-" }}</div>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.perPage"
          @pagination="getList"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Message from "./userRedact/message.vue"; //基本信息
import Business from "./userRedact/business.vue"; //经营范围
import Harvest from "./userRedact/harvest.vue"; //经营范围
import Certification from "./userRedact/certification.vue";
import saleMan from "./userRedact/saleMan.vue"; // 业务员关系
import payWay from "./userRedact/payWay.vue"; // 业务员关系
import multipleAccounts from "./userRedact/multipleAccounts.vue"; //多店/账号
import { createNamespacedHelpers } from "vuex";
const userCenterList = createNamespacedHelpers("userCenterList");
export default {
  data() {
    return {
      dialogVisible: false, //日志记录弹窗
      queryParams: {
        page: 1,
        perPage: 10,
        userId: "", //用户id
        keywords: "", //关键词搜索
      },
      loading: false,
      total: 0,
      logList: [], //日志列表
      activeName: 0,
      content: {
        content: "",
      },
    };
  },
  components: {
    Message,
    Business,
    Harvest,
    Certification,
    saleMan,
    payWay,
    multipleAccounts,
  },
  created() {
    let id = this.$route.query.id;
    this.getUser();
    // this.getQualifyDetail({ user_id: id });
    this.getAddressMsg({ user_id: id });
    this.getScopeBusiness({ user_id: id });
    this.getSalesmanList({ user_id: id });
  },
  methods: {
    ...userCenterList.mapActions([
      "getUserEdit",
      // "getQualifyDetail",
      "getAddressMsg",
      "getScopeBusiness",
      "getSalesmanList",
      "userSyncLog",
    ]),
    getUser() {
      this.getUserEdit({ id: this.$route.query.id });
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab, event) {
      // //console.log(tab, event);
    },
    // 保存
    conserve() {},
    // 关闭
    toleave() {},
    // 富文本类容
    onEditorChange() {},
    openlog() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      this.getList();
      this.dialogVisible = true;
    },
    /** 查询列表 */
    getList() {
      this.queryParams.userId = this.$route.query.id;
      this.loading = true;
      this.userSyncLog(this.queryParams).then((res) => {
        if (res.code == "200") {
          this.logList = res.data.data;
          this.total = res.data.total;
        }
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    min-height: 600px;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
      div {
        width: 100%;
      }
    }
    .richText {
      margin-top: 30px;
      max-width: 800px;
      max-height: 85%;
      min-height: 50vh;
    }
    .compile-BTN {
      margin-top: 30px;
      width: 100%;
      display: flex;
      .cancel-btn {
        cursor: pointer;
        width: 100px;
        height: 40px;
        line-height: 40px;
        background: #f5f6f7;
        color: #c6c7c9;
        text-align: center;
        border-radius: 5px;
      }
      .save-btn {
        margin-left: 20px;
        background: #3d70ff;
        color: #fff;
      }
    }
  }
}
::v-deep .el-tabs__nav {
  width: 100%;
}
::v-deep .el-tabs__item {
  width: 16.59%;
  text-align: center;
}
.absde {
  color: #06b7ae;
  text-align: right;
}
</style>
