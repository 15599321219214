<template>
  <div class="message scorr-roll">
    <div class="Form-BOX">
      <el-form v-if="userAddressMsg">
        <!-- {{ userAddressMsg }} -->
        <el-form-item label="收货人：">
          <div class="from-input">
            <el-input v-model="userAddressMsg.consignee"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="收货人电话：">
          <div class="from-input">
            <el-input v-model="userAddressMsg.mobile"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="所在地区：">
          <el-cascader v-model="userAddressMsg.areaList" :options="AreaList" :props="propsValue" clearable
            @change="changeArea" placeholder="省—市—区"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址：">
          <div class="from-input">
            <el-input v-model="userAddressMsg.address"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="compile-BTN">
            <div class="cancel-btn" @click="toleave">关闭</div>
            <div class="cancel-btn save-btn" @click="conserve">保存</div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const userCenterList = createNamespacedHelpers("userCenterList");
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      labelPosition: true,
      form: {},
      options: [],
      value: "",
      userType: [],
      userData: {
        base: {},
      },
      propsValue: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly: false,
      }, //级联选择器配置项
    };
  },
  computed: {
    ...commonIndex.mapState(["AreaList"]),
    ...userCenterList.mapState(["userAddressMsg"]),
  },
  methods: {
    ...userCenterList.mapMutations(["setUserAddress"]),
    ...userCenterList.mapActions(["postUsersaveAddress"]),
    // 去保存
    async conserve() {
      //console.log(this.userAddressMsg);
      if (
        !this.userAddressMsg.consignee ||
        !this.userAddressMsg.mobile ||
        !this.userAddressMsg.address ||
        !this.userAddressMsg.city ||
        !this.userAddressMsg.district ||
        !this.userAddressMsg.province
      ) {
        this.$message({
          message: "数据未填写完整",
          type: "warning",
        });
        return;
      }
      this.userAddressMsg.id = this.$route.query.id;
      let data = await this.postUsersaveAddress(this.userAddressMsg);
      if (data.code == 200) {
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    toleave() {
      this.$router.push({ path: "/usersCenterList" });
    },
    // 修改省市区
    changeArea(item) {
      this.setUserAddress(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  margin-top: 30px;
  width: 100%;
  height: 75vh;
  min-height: 600px;
  .Form-BOX {
    // width: 650px;
    .compile-BTN {
      width: 100%;
      display: flex;
      margin-top: 200px;
      .cancel-btn {
        cursor: pointer;
        width: 100px;
        height: 40px;
        background: #f5f6f7;
        color: #c6c7c9;
        text-align: center;
        border-radius: 5px;
      }
      .save-btn {
        margin-left: 20px;
        background: #06b7ae;
        color: #fff;
      }
    }
    .from-input {
      max-width: 300px;
    }
  }
}
::v-deep .el-form-item {
  display: flex;
  justify-content: center;
  // width: 100%;
}
::v-deep .el-form-item__label {
  width: 100px;
}
::v-deep .el-cascader {
  width: 250px;
}
::v-deep .el-form-item__content {
  width: 250px;
}
::v-deep .el-input__inner {
  border: 4px 4px   !important;
}
</style>
